const TranslationService = {
    'mk' : {
        details: 'Детали',
        confirmAttendance: 'Потврди присуство',
        map: 'Мапа',
        date: 'Дата',
        startTime: 'Прием',
        location: 'Локација',
        addToCalendar: 'Додади во календар',
        calendar: 'Календар',
        back: 'НАЗАД',
        notConfirmed: 'Вашето присуство не е потврдено',
        confirmText: 'Потврдуваме присуство на вашата свадба',
        numberOfGuests: 'Број на гости?',
        save: 'Зачувај',
        cancelAttendance: 'Откажи присуство',
        guest: 'гостин',
        guests: 'гости',
        willBeChanged: 'ќе биде изменето',
        isConfirmed: 'е потврдено',
        yourPresence : 'Вашето присуство',
        with: 'со',
        loading: 'Се обработува...',
        hourStr: 'часот',
        minGuests: 'Потребен е минумум еден гостин за потврда на поканата!',
    },
    'en' : {
        details: 'Details',
        confirmAttendance: 'Confirm attendance',
        map: 'Map',
        date: 'Date',
        startTime: 'Entrance Time',
        location: 'Location',
        addToCalendar: 'Add to Calendar',
        calendar: 'Calendar',
        back: 'BACK',
        notConfirmed: 'Your presence has not been confirmed',
        confirmText: 'Confirming attendance to your wedding',
        numberOfGuests: 'Number of Guests?',
        save: 'Save',
        cancelAttendance: 'Cancel Attendance',
        guest: 'guest',
        guests: 'guests',
        willBeChanged: 'will be changed',
        isConfirmed: 'is confirmed',
        yourPresence : 'Your presence',
        with: 'with',
        loading: 'Loading...',
        hourStr: 'h',
        minGuests: 'It is required minimum one guest to confirm the invitation!',
    }
}

export default TranslationService;