import React from "react";
// import {Redirect} from 'react-router-dom';
import {useParams} from "react-router-dom";
import cssStyles from './Road.module.css';
import Car from "../Car/Car";

const Road = (props) => {
    let { id } = useParams();
    // const invitation = props.invitation;
    const roadClasses = id ? [cssStyles.road] : [cssStyles.road, cssStyles.wrongLink];
    return (
        <div className={roadClasses.join(' ')}>
            <Car names={'Niko и Kiki'}
            speechText={props.settings.wedding_speech_cloud}/>
        </div>
    );
}

export default Road;