import React from 'react';
import Ticker from 'react-ticker';
import cssStyles from './Coulds.module.css';
const Clouds = () => {

    return(
        <div className={cssStyles.clouds}>
            <Ticker
                speed={3}
                height={400}>
                {({ index }) => (

                        <img
                            src="https://ik.imagekit.io/r18nb96fn9c/wedding/clouds_cPoCliH9U.png"
                            className="loaded"
                            alt=""
                        />
                )}
            </Ticker>
        </div>
    );
}

export default Clouds