import cssStyles from './Mountains.module.css';

const Mountains = () => {
    return (
        <div className={cssStyles.mountains}>
            <img src="https://ik.imagekit.io/r18nb96fn9c/wedding/mounts_9tsefb16Gy.png" alt="Mountains"/>
        </div>
    );
}

export default Mountains;