import React, {createContext, useReducer} from "react";
import Reducer from '../../Services/Reducer';

const initialState = {
    settings: {},
    invitation: {},
    popupType: 'details',
    confirmChecked: false,
    successMessage: '',
    errorMessage: '',
    inputKeyUp: false,
    loading: false,
    openCalendar: false,
    language: 'mk',
    error: null
};

const State = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState);
export default State;