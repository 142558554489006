import cssStyles from "./InvitationInfo.module.css";
import React from 'react';
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import TranslationService from "../../../Services/TranslationService";

const InvitationInfo = (props) => {
    const language = props.language;
    const families = props.data['families' + language] ? props.data['families' + language].map((element)=> {
        return <div key={element}>{element}</div>;
    }) : [];

    const calendarTitle = props.language === 'mk' ? 'Кики&Никола Свадба' : 'Kiki&Nikola Wedding';

    const eventDate = props.data.details_date.split('.');
    const eventTime = props.data.details_start_time.split('-');
    const eventTime2 = eventTime[0].split(':');
    // const start = eventDate[2] + "-" + eventDate[1] + "-" + eventDate[0] + " " + eventTime[0] + ":00+01:00"
    const timeOffset = new Date().getTimezoneOffset() / 60;
    const start = new Date(Date.UTC(parseInt(eventDate[2]), parseInt(eventDate[1])-1, parseInt(eventDate[0]), (parseInt(eventTime2[0])+timeOffset), parseInt(eventTime2[1]), 0));
    const event = {
        title: calendarTitle,
        description: "Be there!",
        start: start,
        duration: [6, "hour"],
    };


    return (
        <div className={cssStyles.details}>
            <div className={cssStyles.info}>
                <h1>{props.data['details_title' + language]}</h1>
                <div className={cssStyles.date}>{TranslationService[props.allLanguages].date}: {props.data.details_date} </div>
                <div className={cssStyles.time}>{TranslationService[props.allLanguages].startTime}: {props.data.details_start_time} {TranslationService[props.allLanguages].hourStr}</div>
                <div className={cssStyles.location}>{TranslationService[props.allLanguages].location}: <a rel="noreferrer" href={props.data.wedding_map} target="_blank">{props.data['details_location' + language]} <span className={cssStyles.mapIcon}><i className="fas fa-map-marker-alt"></i></span></a></div>
                <div onClick={props.handleCalendarClick} className={cssStyles.addToCalendar}>
                    <i className="far fa-calendar-alt"></i> {TranslationService[props.allLanguages].addToCalendar}
                    <div className={props.openCalendar ? cssStyles.active: ''}>
                        <a rel="noreferrer" href={ics(event)} target="_blank">{TranslationService[props.allLanguages].calendar}</a>
                        <a rel="noreferrer" href={google(event)} target="_blank">Google</a>
                        <a rel="noreferrer" href={outlook(event)} target="_blank">Outlook</a>
                        <a rel="noreferrer" href={office365(event)} target="_blank">Office365</a>
                        <a rel="noreferrer" href={yahoo(event)} target="_blank">Yahoo</a>
                    </div>
                </div>
            </div>
            <hr/>
            <div className={cssStyles.additionalInfo}>
                {props.data['details_additional_info' + language]}
            </div>

            <hr/>
            <div className={cssStyles.families}>
                {families}
            </div>
        </div>
    );
}

export default InvitationInfo;