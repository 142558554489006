import React from "react";
import cssStyle from './Trees.module.css';
import Ticker from 'react-ticker';
const Trees = () => {

    return(
        <div className={cssStyle.trees}>
            <Ticker
                speed={30}
            >
                {({ index }) => (
                    <img
                        src="https://ik.imagekit.io/r18nb96fn9c/wedding/trees_gr3DR04o8.png"
                        className="loaded"
                        alt=""
                    />
                )}
            </Ticker>
        </div>
    );
}

export default Trees;