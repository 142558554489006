import React from "react";
import State from "./hoc/State/State";
import './App.css';
import Sky from "./containers/Sky/Sky"
import LcButtons from "./containers/LcButtons/LcButtons";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

function App() {

      return (
          <State>
              <Router>
                  <Switch>
                      <Route strict path="/invitation/:id">
                          <Sky />
                          <LcButtons />
                      </Route>
                      <Route strict path="/" >
                          <Sky />
                      </Route>
                  </Switch>
              </Router>
          </State>
      );
}

export default App;
