import cssStyles from './RibbonHeart.module.css';
const RibbonHeart = ({language}) => {
    const title = language === 'mk' ? 'Кристина и Никола' : 'Kristina & Nikola';
    return(
        <div className={[cssStyles.ribbonContainer, cssStyles.one].join(' ')}>
            <div className={cssStyles.heartContainer}>
                <div className="">
                    <div className={[cssStyles.heart, cssStyles.animateHeart].join(' ')}></div>
                </div>
            </div>
            <div className={[cssStyles.bk, cssStyles.l].join(' ')}>
                <div className={[cssStyles.arrow, cssStyles.top].join(' ')}></div>
                <div className={[cssStyles.arrow, cssStyles.bottom].join(' ')}></div>
            </div>

            <div className={[cssStyles.skew, cssStyles.l].join(' ')}></div>

            <div className={cssStyles.main}>
                <div>{title}</div>
            </div>

            <div className={[cssStyles.skew, cssStyles.l].join(' ')}></div>

            <div className={[cssStyles.bk, cssStyles.r].join(' ')}>
                <div className={[cssStyles.arrow, cssStyles.top].join(' ')}></div>
                <div className={[cssStyles.arrow, cssStyles.bottom].join(' ')}></div>
            </div>

        </div>
    );
}

export default RibbonHeart