import React, { useContext, useState } from "react";
import { Context } from "../../hoc/State/State";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import cssStyles from './LcButtons.module.css'
import Lcbutton from "../../components/Lcbutton/Lcbutton";
import InvitationPopup from "../../components/InvitationPopup/InvitationPopup";
import RequestService from "../../Services/Request";
import TranslationService from "../../Services/TranslationService";

const LcButtons = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('details');
    const [state, dispatch] = useContext(Context);

    const language = state.language;

    const openPopupHandler = (e, popupType) => {
        e.preventDefault();
        setShowPopup(true);
        setPopupType(popupType);
    }

    const closePopupHandler = (e) => {
        e.preventDefault();
        setShowPopup(false);
        dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: '' });
    }

    const openMap = (e) => {
        e.preventDefault();
        const link = state.settings.wedding_map;
        window.open(link, '_blank').focus();
    }

    const handleConfirm = (e) => {
        let checked = state.confirmChecked
        dispatch({type: 'SET_CONFIRMATION_CHECKED', payload: !checked});
    }

    const handleSaveGuest = (e) => {

        if(e.target.disabled){
            return;
        }

        if(state.invitation.confirmed_guests < 1 || state.invitation.confirmed_guests === '') {
            dispatch({ type: 'SET_ERROR_MESSAGE', payload: TranslationService[language].minGuests});
            setTimeout(function () {
                dispatch({ type: 'SET_ERROR_MESSAGE', payload: ''});
            }, 3000);

            return;
        }

        const request = new RequestService();
        request.method = 'post';
        request.endpoint = 'saveGuest';
        request.addPostParam('invitationID' , state.invitation.invitation_id);
        request.addPostParam('numberOfGuests' , state.invitation.confirmed_guests);
        request.addPostParam('confirmChecked' , state.confirmChecked);
        request.addPostParam('language' , state.language);
        dispatch({ type: 'SET_LOADING', payload: true });
        dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: '' });
        request.execute(function (response) {
            dispatch({ type: 'SET_LOADING', payload: false });
            dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: response.data.message });
            dispatch({ type: 'SET_INVITATION', payload: response.data.invitation });
            dispatch( { type: 'SET_INPUT_KEYUP', payload: false });
            setTimeout(() => {
                dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: '' });
            }, 3000);
        });

    }

    const handleCancel = () => {
        const request = new RequestService();
        request.method = 'post';
        request.endpoint = 'cancelGuest';
        request.addPostParam('invitationID' , state.invitation.invitation_id);
        request.addPostParam('language' , state.language);
        dispatch({ type: 'SET_LOADING', payload: true });
        dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: '' });
        request.execute(function (response) {
            dispatch({ type: 'SET_LOADING', payload: false });
            dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: response.data.message });
            dispatch({ type: 'SET_INVITATION', payload: response.data.invitation });
            dispatch({ type: 'SET_CONFIRMATION_CHECKED', payload: false });
            setTimeout(() => {
                dispatch({ type: 'SET_SUCCESS_MESSAGE', payload: '' });
            }, 3000);
        });
    }

    const handleGuestNumber = (e) => {
        let guests = e.target.value;

        if(parseInt(guests) > state.invitation.number_of_guests) {
            e.target.value = state.invitation.number_of_guests;
        }

        dispatch({ type: 'SET_INVITATION', payload: {
            ...state.invitation,
                confirmed_guests: e.target.value
            }});
        dispatch({ type: 'SET_INPUT_KEYUP', payload: true});
    }

    const handleCalendarClick = () => {
        console.log(state.openCalendar)
        dispatch({ type: 'SET_OPEN_CALENDAR', payload: !state.openCalendar});
    }

    let popup = '';

    if(Object.entries(state.settings).length > 0) {
        popup = <InvitationPopup
            saveGuest={handleSaveGuest}
            showPopup={showPopup}
            popupType={popupType}
            data={state}
            isChecked={state.confirmChecked}
            checkConfirmation={handleConfirm}
            closeInvitation={closePopupHandler}
            handleGuestNumber={handleGuestNumber}
            inputKeyUp={state.inputKeyUp}
            loading={state.loading}
            handleCancel={handleCancel}
            handleCalendarClick={handleCalendarClick}
        />;
    }
    return (
        <Wrapper>
            <div className={cssStyles.buttons}>
                <Lcbutton
                    btnText={TranslationService[language].details}
                    onClickFunction={(e) => openPopupHandler(e, 'details')}
                 />
                <Lcbutton
                    btnText={TranslationService[language].confirmAttendance}
                    hoverColor="blob_green"
                    onClickFunction={(e) => openPopupHandler(e, 'confirmation')}
                />
                <Lcbutton
                    btnText={TranslationService[language].map}
                    hoverColor="blob_orange"
                    onClickFunction={openMap}
                />
            </div>

            <div className={cssStyles.buttons_mobile}>
                <a onClick={(e) => openPopupHandler(e, 'details')} href="/#" className={cssStyles.blue}>{TranslationService[language].details}</a>
                <a onClick={(e) => openPopupHandler(e, 'confirmation')} href="/" className={cssStyles.green}>{TranslationService[language].confirmAttendance}</a>
                <a onClick={openMap} href="/" className={cssStyles.orange}>{TranslationService[language].map}</a>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                    </filter>
                </defs>
            </svg>
            {popup}
        </Wrapper>
    );
}

export default LcButtons;