import cssStyle from './AnimatedLoader.module.css';
import TranslationService from "../../Services/TranslationService";

const AnimatedLoader = (props) => {
    const classes = props.loading ? [cssStyle.animationContainer, cssStyle.active] : [cssStyle.animationContainer];
    return(
        <div className={classes.join(' ')}>
            <div className={cssStyle.lds_heart}>
                <div></div>
            </div>
            {TranslationService[props.language].loading}
        </div>
    );
}

export default AnimatedLoader;