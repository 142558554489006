const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return {
                ...state,
                settings: action.payload
            };
        case 'SET_INVITATION':
            return {
                ...state,
                invitation: action.payload
            };
        case 'SET_CONFIRMATION_CHECKED':
            return {
                ...state,
                confirmChecked: action.payload
            };
        case 'SET_SUCCESS_MESSAGE':
            return {
                ...state,
                successMessage: action.payload
            };
        case 'SET_ERROR_MESSAGE':
            return {
                ...state,
                errorMessage: action.payload
            };
        case 'SET_INPUT_KEYUP':
            return {
                ...state,
                inputKeyUp: action.payload
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload
            };
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload
            };
        case 'SET_OPEN_CALENDAR':
            return {
                ...state,
                openCalendar: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;