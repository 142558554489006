import cssStyles from "./ConfirmationInfo.module.css";
import AnimatedLoader from "../../AnimatedLoader/AnimatedLoader";
import TranslationService from "../../../Services/TranslationService";

const ConfirmationInfo = (props) => {

    const successMessage = props.successMessage !== '' ? <p className={cssStyles.successMessage}>{props.successMessage}</p> : '';
    const errorMessage = props.errorMessage !== '' ? <p className={cssStyles.errorMessage}>{props.errorMessage}</p> : '';
    const gosti = parseInt(props.data.confirmed_guests) > 1 ? TranslationService[props.allLanguages].guests : TranslationService[props.allLanguages].guest;
    const kje = props.inputKeyUp ? TranslationService[props.allLanguages].willBeChanged : TranslationService[props.allLanguages].isConfirmed;
    const confirmedMessage = (props.data.confirmed_guests !== '') && (parseInt(props.data.confirmed_guests) > 0) ? <p className={cssStyles.confirmedMessage}>{TranslationService[props.allLanguages].yourPresence} {kje} {TranslationService[props.allLanguages].with} {props.data.confirmed_guests} {gosti}.</p> : <p className={cssStyles.confirmedMessage}>{TranslationService[props.allLanguages].notConfirmed}.</p>;

    return (
        <div className={cssStyles.details}>
            <div className={cssStyles.info}>
                {confirmedMessage}
                <p>
                    <label htmlFor="confirmed" >
                        <input
                            className={cssStyles.checkbox}
                            id="confirmed"
                            onChange={(e)=>props.checkConfirmation(e)}
                            checked={props.isChecked ? 'checked' : ''}
                            name="confirmed"
                            type="checkbox"/>
                        {TranslationService[props.allLanguages].confirmText}
                    </label>
                </p>
            </div>

            <div className={cssStyles.additionalInfo}>
                <h1>{TranslationService[props.allLanguages].numberOfGuests}</h1>
                <input pattern="\d" defaultValue={props.data.confirmed_guests} onKeyUp={(e) => props.handleGuestNumber(e)} disabled={!props.isChecked ? 'disabled' : ''} max={props.data.number_of_guests} min="1" type="number"/>
            </div>
            <div className={cssStyles.sendBtn}>
                <button onClick={(e) => props.saveGuest(e)} disabled={!props.isChecked ? 'disabled' : ''}>{TranslationService[props.allLanguages].save}</button>
                {props.data.confirmed ? <button onClick={(e) => props.handleCancel(e)} >{TranslationService[props.allLanguages].cancelAttendance}</button> : ''}
            </div>
            <AnimatedLoader language={props.allLanguages} loading={props.loading} />
            {successMessage}
            {errorMessage}
        </div>
    );
}

export default ConfirmationInfo;