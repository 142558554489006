// import Notiflix from "notiflix";
import axios from 'axios';

/**
 * Base request class for all requests
 */
export default class RequestService {
    /**
     * class constructor method
     */
    constructor() {
        /**
         * Base api endpoint
         * @type {string}
         */
        this.baseUrl = process.env.REACT_APP_API_BASE_URL;
        /**
         * Api Endpoint
         * @type {null|String}
         */
        this.endpoint = null;
        /**
         * Request method, default post
         * @type {string}
         */
        this.method = 'get';
        /**
         * Request
         * @type {{}}
         */
        this.request = null;
        /**
         * Response
         * @type {{}}
         */
        this.response = null;
        /**
         * Response type, possible values are:
         * arraybuffer, blob, document, json, text, stream
         * @type {string}
         */
        this.responseType = 'json';
        /**
         * Request headers, default is empty object
         * @type {{}}
         */
        this.headers = { 'Content-Type': 'application/json' };
        /**
         * Post parameters
         * @type {{}}
         */
        this.postParams = {};
        /**
         * Get parameters
         * @type {{}}
         */
        this.getParams = {};
        /**
         * Store logging data from requests
         * @type {Array}
         */
        this.logInfo = [];
        /**
         * Should we use logging or not
         * @type {boolean}
         */
        this.logData = true;

        /**
         * Abort current request
         * @type {null}
         */
        this.abort = () => {
        };
    }

    /**
     * Add header to request
     * @param key
     * @param value
     * @returns {boolean}
     */
    addHeader(key, value) {
        if (!key || !value) {
            return false;
        }
        this.headers[key] = value;
    }

    /**
     * Add post parameter
     * @param key
     * @param value
     */
    addPostParam(key, value) {
        this.postParams[key] = value;
    }

    /**
     * Add get parameter
     * @param key
     * @param value
     */
    addGetParam(key, value) {
        this.getParams[key] = value;
    }

    /**
     * Execute request
     * @param success
     * @param error
     * @returns {*}
     */
    execute(success, error) {
        /**
         * Execute real request
         */
        this.request = axios(this.getRequestConfig());
        this.request
            .then((resp) => {
                if (typeof success === 'function') {
                    success(resp);
                }
            })
            .catch((err) => {
                if (typeof error === 'function') {
                    error(err);
                } else {
                    // Notiflix.Report.Failure( 'Грешка', err.toString(), 'ОК' );
                    console.log(err.toString())
                }
            });
        return this.request;
    }

    /**
     * Build config for request
     * @returns {{method: string, url: String, baseURL: string, responseType: string, headers: {}}}
     */
    getRequestConfig() {
        const CancelToken = axios.CancelToken;
        let config = {
            method: this.method,
            url: this.endpoint,
            baseURL: this.baseUrl,
            responseType: this.responseType,
            cancelToken: new CancelToken((c) => {
                this.abort = c;
            }),
            headers: this.headers,
        };
        if (this.method === 'post' || this.method === 'put' || this.method === 'patch') {
            config.data = this.postParams;
        } else if (this.method === 'get') {
            config.params = this.getParams;
        }
        return config;
    }
}