import React, {useContext} from "react";
import { useParams } from "react-router-dom";
import cssStyles from './Car.module.css';
import {Context} from "../../hoc/State/State";
const Car = (props) => {

    const [state] = useContext(Context);
    let { id } = useParams();

    let language = state.language === 'mk' ? '' : '_' + state.language;
    let speechText = state.settings['wedding_speech_cloud_error' + language];

    const renderNames = (names) => {
        if(!names) {
            return ;
        }
        if(names.length < 1) {
            return;
        }
        if(names.length === 1 ){
            return names[0].name;
        }

        let namesString = '';

         names.map((person, index) => {
             if((names.length - 1) === (index + 1) ) {
                 return namesString += person.name + ' и ';
             } else if(names.length === (index + 1)){
                 return namesString += person.name;
             }
             else {
                 return namesString += person.name + ', '
             }
        });

         return namesString;
    }

    if(id && Object.entries(state.invitation).length > 0) {
        const sing_plur = state.invitation.names.length > 1 ? 'ве' : 'те';
        let names = renderNames(state.invitation.names);

        speechText = state.settings['wedding_speech_cloud' + language].replace('{names}', names).replace('{sing_pulr}', sing_plur)
    }

    console.log('invitation: ', renderNames(state.invitation.names));

    return(
        <div className={cssStyles.car}>
            <div className={cssStyles.speech_bubble}>
                <p>{speechText}</p>
            </div>
            <img className={cssStyles.the_car} src="https://ik.imagekit.io/r18nb96fn9c/wedding/car_famBbd2Po.png" alt="" />
            <img className={[cssStyles.wheels, cssStyles.front_wheel].join(' ')} src="https://ik.imagekit.io/r18nb96fn9c/wedding/wheel_EqFcXZrDcY.png" alt="" />
            <img className={[cssStyles.wheels, cssStyles.back_wheel].join(' ')} src="https://ik.imagekit.io/r18nb96fn9c/wedding/wheel_EqFcXZrDcY.png"  alt="" />
        </div>
    );
}

export default Car