import React, {useEffect, useContext} from 'react';
import Request from "../../Services/Request";
import { Context } from "../../hoc/State/State";
import { useParams, useHistory } from "react-router-dom";
import cssStyle from './Sky.module.css';
import Mountains from "../../components/Mountains/Mountains";
import Clouds from "../../components/Clouds/Clouds";
import Trees from "../../components/Trees/Trees";
import Road from "../../components/Road/Road";
import RibbonHeart from "../../components/RibbonHeart/RibbonHeart";

const request = new Request();

const Sky = () => {
    const [state, dispatch] = useContext(Context);
    let { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        if(Object.entries(state.settings).length === 0) {
            request.endpoint = 'settings';
            request.execute((response) => {
                dispatch({type: 'SET_SETTINGS', payload: response.data.settings})
            });
        }

        if(Object.entries(state.invitation).length === 0 && id) {
            request.endpoint = 'guest';
            request.addGetParam('guestId', id);
            request.execute((response) => {
                dispatch({type: 'SET_INVITATION', payload: response.data.invitation});
                dispatch({type: 'SET_CONFIRMATION_CHECKED', payload: response.data.invitation.confirmed});
                dispatch({type: 'SET_LANGUAGE', payload: response.data.invitation.language})
            }, (error) => {
                console.log('error: ', error);
                history.push('/');
            });
        }
    });

    let road = '';

    if (Object.entries(state.settings).length > 0) {
        road = <Road settings={state.settings} />;
    }

    return (
        <div className={cssStyle.Sky}>
            <Clouds />
            <RibbonHeart language={state.language} />
            <Trees />
            <Mountains />
            {road}
        </div>
    )
}

export default Sky;