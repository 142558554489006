import cssStyles from './InvitationPopup.module.css';
import InvitationInfo from "./InvitationInfo/InvitationInfo";
import ConfirmationInfo from "./ConfirmationInfo/ConfirmationInfo";
import TranslationService from "../../Services/TranslationService";

const InvitationPopup = (props) => {

    const language = props.data.language === 'mk' ? '' : '_' + props.data.language;
    const popupClasses = props.showPopup ? [cssStyles.wedding_popup, cssStyles.active] : [cssStyles.wedding_popup];
    let popupContent = props.popupType === 'details' ?
        <InvitationInfo
            data={props.data.settings}
            handleCalendarClick={props.handleCalendarClick}
            openCalendar={props.data.openCalendar}
            language={language}
            allLanguages={props.data.language}
        /> :
        <ConfirmationInfo
        saveGuest={props.saveGuest}
        isChecked={props.isChecked}
        checkConfirmation={props.checkConfirmation}
        data={props.data.invitation}
        successMessage={props.data.successMessage}
        handleGuestNumber={props.handleGuestNumber}
        inputKeyUp={props.inputKeyUp}
        loading={props.loading}
        handleCancel={props.handleCancel}
        language={language}
        allLanguages={props.data.language}
        errorMessage={props.data.errorMessage}
        />

    return (
        <div className={popupClasses.join(' ')}>
            <div className={cssStyles.content_container}>
                <div onClick={props.closeInvitation} className={cssStyles.close_popup}>
                    <img src="/images/chevron-left.svg" alt=""/>
                    {TranslationService[props.data.language].back}
                </div>
                <img className={cssStyles.top_flower} src="https://ik.imagekit.io/r18nb96fn9c/wedding/top-flowers_cY_50Nzgf.png" alt=""/>
                {popupContent}
                <img className={cssStyles.bottom_flower} src="https://ik.imagekit.io/r18nb96fn9c/wedding/flowers-bottom_mzbmKIMap.png" alt=""/>
            </div>
        </div>
    );
}

export default InvitationPopup;