import cssStyles from './Lcbutton.module.css';
import { Link } from "react-router-dom";
const Lcbutton = (props)=> {
    const href = props.btnHref ? props.btnHref : '/'
    const btnColor = props.hoverColor ? [cssStyles.blob_btn, cssStyles[props.hoverColor]] : [cssStyles.blob_btn, cssStyles.blob_blue];
    const btn = !props.onClickFunction ?
        <Link to={href} className={btnColor.join(' ')}>
        {props.btnText}
        <span className={cssStyles.blob_btn__inner}>
               <span className={cssStyles.blob_btn__blobs}>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>

              </span>
            </span>
    </Link> :
        <a href="/#" className={btnColor.join(' ')}  onClick={props.onClickFunction}>
            {props.btnText}
            <span className={cssStyles.blob_btn__inner}>
               <span className={cssStyles.blob_btn__blobs}>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>
                <span className={cssStyles.blob_btn__blob}></span>
              </span>
            </span>
        </a>

    return btn;
}

export default Lcbutton;